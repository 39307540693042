import React, { useEffect, useState, useRef } from "react";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import boxingGloves from "./styles/boxing-gloves.png";
import ticket from "./styles/ticket.png";
import shake from "./styles/shake-hands.png";
import boxingShorts from "./styles/boxing-shorts.png";
import boxingRing from "./styles/boxing-ring.png";
import robe from "./styles/robe.png";
import plotter from "./styles/plotter.png";
import press from "./styles/lectern.png";
import sessions from "./styles/sand-bag.png";
import nftImage1 from "./styles/nftimage1.png";
import nftImage2 from "./styles/nftimage2.png";
import { Collapse } from 'antd';

// var myContract = new Web3.eth.Contract([...], '0xde0B295669a9FD93d5F28D9Ec85E40f4cb697BAe', {
//   from: '0x1234567890123456789012345678901234567891', // default from address
//   gasPrice: '20000000000' // default gas price in wei, 20 gwei in this case
// });


const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #F3164A;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: #F3164A;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  width: 400px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: #F3164A;
  text-decoration: none;
`;


function App() {


  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [ethPriceGBP, setEthPriceGBP] = useState(13411111);
  const [crossPrice, setCrossPrice] = useState(123);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "0xc83Cf1694bc20b917C969FB4A2571b7d8ef625Aa",
    SCAN_LINK: "https://etherscan.io/address/0xc83Cf1694bc20b917C969FB4A2571b7d8ef625Aa",
    NETWORK: {
      NAME: "Ethereum",
      SYMBOL: "ETH",
      ID: 1,
    },
    NFT_NAME: "BenNFT",
    SYMBOL: "BNFT",
    MAX_SUPPLY: 5555,
    WEI_COST: 218331731539377820,
    DISPLAY_COST: 0.15,
    GAS_LIMIT: 120000,
    MARKETPLACE: "opensea",
    MARKETPLACE_LINK: "https://opensea.io/collection/BenNFT-V2",
    SHOW_BACKGROUND: false,
  });


  const claimNFTs = () => {
    const usersArray = ["0xf01b4f7153689ec2f53a48818eda8f740a6f41b3",
      "0x5761bd79640369d862ac9e29b12bca5ac21f4bcc",
      "0x56226ae66c07a7f038b0c6f215e7cdc82e325c66",
      "0x316685cd3527fd2f3c85539c126b347105836b76",
      "0x749dbd0a44d26d1920d5802d1cb23988d6780306",
      "0xd886643f4fb00bdc617736591a993c5b6e6c378b",
      "0x2aed794fd50be9da6e4b7315bbcab77e0cf7e2fb",
      "0x10a74d536d07baab67b4537d59a943205861ec31",
      "0xfd266d6ebe65e9ba5f13572e0e0f3948a674249a",
      "0x1da83774a0b5b3fe679c1da028bc4719f0966bd7",
      "0xd7599a1a044fa544e335b08167ecc03bfcc3e971",
      "0xa533b36f52e3f67d9e8aa330a775d512d9994443",
      "0xffc12e48ee45573607b1d5f39492bf35f2db053e",
      "0x5450677f8a8fc669d2476a6cdea9bb6a9d475721",
      "0xe29c6435059791e7a61e986310d0a9d4f8c2b140",
      "0xf02e559b48146227805320867d91b89a8e7a8e77",
      "0xa533b36f52e3f67d9e8aa330a775d512d9994443",
      "0x42c2a05acb339a5184bec5e6231b49e6b958c4f9",
      "0x0c05bc8bb184b29a04db4872f0784cc62bd0cb61",
      "0x729166e9ab9c63c276461c26fe20c41af96620b6",
      "0x910cef6974f9007d6d2ab48525285f74b9f32c4a",
      "0xc0b1b236171da3330d293c86625dd3a0967e9ca1",
      "0x7968f3e3609ee7b160fd0792d316558bc29be8d0",
      "0x40bf41dea71f9bb5cf6503f0b309fd919c455c3c",
      "0xfe7c4cb471140df33b60c7e921708612dec44794",
      "0x4351ca1114cf78af5712efe67ce4df8aa04e5249",
      "0x69dcd5b254534e03814683958bc15326c9aeca72",
      "0xa6adbcdf4b1c4fc440f3cb6c035fa76b41ed6c5b",
      "0x6507b7b1f9dab72da60399e3a76d7b06fcea2b98",
      "0xffc12e48ee45573607b1d5f39492bf35f2db053e",
      "0xb1bef758d3ae913eed3d5d3433e848c1aee3ae4a",
      "0x5450677f8a8fc669d2476a6cdea9bb6a9d475721",
      "0x8af5080f336e978b2987fced83f51e8f169e28bf",
      "0x4c554f9914f9ffe2bfa44b473b8caeaeb4750861",
      "0x483afda5eece4ffdd95e83531733bc93d82e003a",
      "0x0b167add50633c27d6a9a57f3c2d537c6efeabf3",
      "0x1ba57cc9e45f37f52194f35caf857163d20bf296",
      "0x1da83774a0b5b3fe679c1da028bc4719f0966bd7",
      "0x819fbb2d9880d23792fd9d1cde4c153bf9956344",
      "0x91ee726ee03b56d276cd87ae0bbb7399269d35ec",
      "0x506cc2f31d7ac86f60fd015790c31cbd93cba840",
      "0x91401918cb5c03d727731b81d0f92e4fc6ac97db",
      "0xff65049a1d9ab2c8ad4c7d2a6a18897a070c1f50",
      "0x2a9a03dbf0fd342c0132ce935de2974e6a45db67",
      "0xed54b7724ddf94fa94070a464fa048679e5b17d9",
      "0xd7599a1a044fa544e335b08167ecc03bfcc3e971",
      "0xfcbf0ddff4d1034af94396e6e749ed7b40fe0a58",
      "0xca590c80102dba8128404fcc18581893b3555dbb",
      "0x12756b8b90d03ecc9afdb08fe3e4a6a95f50f125",
      "0x56226ae66c07a7f038b0c6f215e7cdc82e325c66",
      "0xfd266d6ebe65e9ba5f13572e0e0f3948a674249a",
      "0xe911b64bd8e2106bb91acd31cf6bc5cb72398486",
      "0x5761bd79640369d862ac9e29b12bca5ac21f4bcc",
      "0x10a74d536d07baab67b4537d59a943205861ec31",
      "0xdba0b4963791c24448f33349e4a997cd46f21f8b",
      "0x63716d3141f7711402f3a99d2e9a645b229b8de2",
      "0x53e1d7acbec2f8f20c774b09957780ec1b8b86aa",
      "0x7a96510cbdd0809d9e2d3166bc9e50eeefb894d3",
      "0xd45f27404efc2b96e95aaacaae487dc0e771a28e",
      "0xc689f19d2205bf82f9d1f4296c5b999344b9861d",
      "0x3ad0c6c9256330b63af029e35289edc81af1e5f8",
      "0xc4c6ed8a22306bc3491d6f5e7a536abe2ca9e003",
      "0x35cceffd13a834128429349052da49875821318c",
      "0x10af3c0b921684a1c335ce4ec35c3deff339d1a1",
      "0x81ecc01fb730b4bc6edb49c26f626b51772105b9",
      "0x4cde10196c770ac25c1c6a50f523a52ea6807c27",
      "0x6ed655ed54910c1f85391f8e755f92927a381439",
      "0x453f2a8e2ee8107e056bc71cdbf29322a1b73a53",
      "0x1e868e0f5948fc94ed99dde0d0aba939e7677b47",
      "0x8377e8f2e43a6ff6e4e7c16802b24fd2c00febd9",
      "0x30e8bed0160e785f5095a5ec10d1e44829e5576a",
      "0x96630701a16c8e354cc02bf125bacb15fb2d9fd7",
      "0xae25befadf944a40ceaa987b23b001cce60eac61",
      "0x8b23478348128a04c50ca7b52127ee96da417fa7",
      "0x97608a94af473556c54e0943f0dd27d307d11e08",
      "0xc7910c0aa7438139afd9b2c48f76beedf180560f",
      "0x2e312984f24b2a43ca99b663c8dcf7f962814c3c",
      "0x8d270e79e68d2c1080bbbf53c572cf82e665ebe4",
      "0x9aaca94fd9bc632822e2426d54644e7f57427af6",
      "0x4d5368ef387897798c7fb0b1f34f5a21eeae36f5",
      "0x7d73c1fd3c744ece8aadc06a98a908d29be9815c",
      "0xe91300863a3afdb1fba877ac7ee9e12556f80d32",
      "0x8bcf7e01a3860f8c95a0025faed60e61373aea41",
      "0x665f5b4f2d20ec44b96e58ac99a0d8260b859382",
      "0x3f994e9794f22ad443333e833776404818425563",
      "0x2172c71c8eec009b48f3851681183a46ed1e994a",
      "0x6736c939219f7f4829bb1d0b154f30090a838527",
      "0x22f08890bb0199c04f209f1289548d4ef3a3972c",
      "0x3770c42e97942f2b8bf04058c6bfa3ed95918467",
      "0xf0470bfce7f2450dc0f781aa610877f3242e355f",
      "0x5d46de2217f081e0294bf49d35772e9c56507861",
      "0x9ecf90aeefa89c363122f49d54f68ae62441ad19",
      "0x6a16dfd9433ee0078b87e7515dbcfb604e56b13f",
      "0x81fa9efe2686c4c25800652059d523cbc3f71371",
      "0x794722b552d3ce8b211befb77fe8fc92936a195f",
      "0xaebbdfb1dcb91de3f09f569971146ec757f8b5fe",
      "0x794cd68cbe7e25ef6fb3374642d223051a9d03ee",
      "0x67e98602b5ad8bb6bf506a5b9593ac6c11d927f7",
      "0x51794f558825d7e3e148a04230345c1adde87825",
      "0x03605d57f72425c36b929ab82dd43bd3c8765ed7",
      "0xb2b7cf04a31d943fbf14ea4575112d9b3aa2d3e3",
      "0xfc689e98438a37d855fdc72eb10028a66b1d3a0a",
      "0xe3be8df2324a6566fcf77f9c756f20012679fc2c",
      "0x7f522989a221fd02fc60cc32faae4400c496729d",
      "0x2c0e5ec8794aeba837a894dff2c3a605a353e56e",
      "0x9b9ff8e92f29b357fd3a547c613877896c86643a",
      "0xa2dedd34e1c6331110a5b288018f35e0a10d1357",
      "0x8285253cdcd7ac274a908c0f2e53af2afb96c7ea",
      "0x64096773439e1ace82b5c95674ed342e0658b2b7",
      "0x7620145eee9f5fec39a58a93606d03696731d058",
      "0xea1b056043bb0b03451f40ef0ae8566409952284",
      "0x4c93dd4ccf048d2d1640a71b351f5310d784c6c3",
      "0x55fc9817f64939e60ac83ecb546fe28db3d4ca9e",
      "0x2cee2672bbcbdfa98696f0d6abc684c051473818",
      "0x42ee32354a3878fd0e2fad76bc7563083bf3f5fe",
      "0x2bf14479ea1a36600f699d02fa8faa70f1fe2728",
      "0x7dda8b1c5156980a06f65abec0c28849af81bfbd",
      "0x30e8bed0160e785f5095a5ec10d1e44829e5576a",
      "0x8377e8f2e43a6ff6e4e7c16802b24fd2c00febd9",
      "0x1e868e0f5948fc94ed99dde0d0aba939e7677b47",
      "0x453f2a8e2ee8107e056bc71cdbf29322a1b73a53",
      "0x6ed655ed54910c1f85391f8e755f92927a381439",
      "0x4cde10196c770ac25c1c6a50f523a52ea6807c27",
      "0x56b238ee0a4e2f8deedd5ac789a3e63af6b07681",
      "0x1baff971123e07c2312004a6bd519fa33085450d",
      "0x6922a2b40cafac5cb2eff0f4270e6b1c321c410c",
      "0x1bfa36ea533bae7fa8eb8dc518c80bd91335e936",
      "0x5a62642af5ea1dee60e7df032ef6d2177162ed44",
      "0x2e82494083d823173249d67e3b7bdad17425e028",
      "0x9705f4084fec8bb49b74977f4faaaa8ed8de9378",
      "0x2bd32418ef2ff428ce8b0cbae167026cdc89a467",
      "0x146065db2c644bf5d25510639d2718dc9e9ed701",
      "0x6c2227158c65870ff346287cbd123cb840cdd62d",
      "0x5e599fea95e95ea83835e94232633dc5fccf3fc5",
      "0x9b9ff8e92f29b357fd3a547c613877896c86643a",
      "0xe56c997eb15e5d012fac2a09ad24b48ca4dca820",
      "0xc7840591ddaf780924ea7fbb06aa3fdffeeccc36",
      "0x254dd957e31ccdeab0b28ca67550ef998b74c540",
      "0x8209a0f91fe84e756a624f079e51e9e29fdc252a",
      "0x1a40323a8059068b97a6d79ddf120de9d33cb092",
      "0x2adc7d0d398661c77af81611431ca52be673d791",
      "0x2157d13fe2c74fa1f77caf5245882530528ff985",
      "0x100105dc358a639c091c2e111f660e080e7382cb",
      "0x38db7c641918348ed545e563703dc553229048ff",
      "0x420cc25bbc0f602fa7cb493717079b4d893ae76c",
      "0x2da791359933011b4f74042a241fbbf09f6cbcab",
      "0xb3407f85880a874265c5eae427db0dcac866adc1",
      "0x250479e7407206bfea693e2cdc7b9c97912362e1",
      "0x67a1b608e1080815911c394997a88740e196c198",
      "0xcb33e08812df897875e59662ef1b6d06a6909bf3",
      "0x390a59a3e5f925e1765173ca0ef30f7f7b1a6106",
      "0x83d8fe37105f254ae782285545ded843d417272d",
      "0x8ad400c7a6db13159bae9c2bea879501e981788d",
      "0x9856da3209c6e2ad02b9fbce1f2de8bab190f74c",
      "0x8cd4fc20cc3933126a2b8882cd950af21e416235",
      "0xa64e6a0885667a9c21de862c830ad688c185ac6e",
      "0xb7ecbf7070e3fbb20ae7ad431933895439f7f32e",
      "0x4dbaf6752257117019a3b54f4656a9fd5e6a8f54",
      "0x4e0ea1b77dcf2de9645bf3a0e379d034b5640b3b",
      "0xf0d65372498f8f15232f733ddda65c8c55e5e9b4",
      "0x73bd258a654f95621c7e8119e68df4a30cb1f22f",
      "0x847845b158d34491386c4e9adb21824de8375022",
      "0xee150a5ebf3a2684c1b8a928b6234c18dd1cdf9c",
      "0x57fbe4e3e478a1e27e0fd6242f757453138e28ab",
      "0x83c1baf7de0f9846f0fac84fab2b3242e43c25a7",
      "0x318c966cf05531f682ce7cbe39b831dce000f45c",
      "0xa1163cbf7c3cca1a1014a2310be3d6a09b95ef5a",
      "0x0e380166b57b0095ff3158ce637a72ba03c7d7fa",
      "0x8440cc32891ae045de2016a7f1c38012f41909e5",
      "0xdee3ccce48205c662990b0a6ca664e6c5daeb2ee",
      "0x017ee114957b92dabdc88ee01fea817a4c1f2055",
      "0xed952771f20bb026cc17addf9bb0cf8beb589cf5",
      "0x8b719af23eb5a9d9844ebcf28903fc3bdfde54ef",
      "0x6cf0ae42a172606d14274e51c41cd18a382b3ba2",
      "0xc35577b56e3ae215149668557820e307530292f5",
      "0x2bc477a7fa30829ce4ec162beb60734274cc32b6",
      "0x81373d0e8ad6ad27387f8d89443c585ca835ddab",
      "0x262b3a8ac18742b096280203bd783059f97dc4fa",
      "0x098d3b43a19a3adf692dda194d61709d96c98575",
      "0xac423b380c7d69094dc1ff93a92cc6e9882275f5",
      "0x45b3b6f94a8c8c94c953d4a2109011eed8957a69",
      "0x189e95603298257390ef807e077887004718c8c1",
      "0xbead784ddad5ec58050ca3f939027577b046b86c",
      "0xeaf7814cdf7236bc5992d19cc455f9c92b00aa9e",
      "0xb464f7b528264398cb963bc8bd33bdacde635154",
      "0xb3924ecf19c2ef7fb7ce3ecddd22ec96c77a3f64",
      "0x053e6294400a9268e35df445624f58087c7f388f",
      "0x339399b830383c94f76343cba6d2465bbdeff4e4",
      "0x02860e1bbd7cf13f438840588e0fcb50fc818e8f",
      "0x4c18d8e4bf02e362ed126a8866b1e0affdde50c5",
      "0x8c2aa88914c28e26586999c1791b07ecda04a415",
      "0x10e0746c30773e0feff9452bc652107db5ed7c13",
      "0xd37dabe2ccca72166f41c236b91ca36494fe5919",
      "0xb683ea632db3be49336928b3c3d197a9bfe3a2f2",
      "0x3f4e54eb00f833f2e8143676a8721df14d95bd6f",
      "0x693549c881b2d80a2743b25d55f5a1d61f2b80d5",
      "0xdfabece9789833f722113727fe49360b14d815d4",
      "0xa0bd5c3838342961e09dc2225c340f4a1818ab6d",
      "0xadfaa4929f0bb544503d322f412ee81b7073b466",
      "0x345519d4a5d56db069f53eb585d4f6a6abc7473d",
      "0xe6527edcec7464f9a6fa0e7bd38afdd216c63dd1",
      "0x99a832baffa31e0bb484b6111c2871736bfb27d5",
      "0xcf4152b9de5b221f884b40759c71b492b5ee79df",
      "0x35deef20e70537afce136ab5698be6cf38588da5",
      "0x5952fb8620a37c7e4fa9cb25f1cecd6b8802ac17",
      "0x12af4675299e0e72d12695fa9c7d9e3195d32380",
      "0x48d9742af29eb0805ca5402a37b6c21b48491b13",
      "0xb4d133c96d8833ea8a2275a1c0ce6d2204661624",
      "0x1ee5312803a3fabdfd673e51ad9eb4e6d648584d",
      "0x1d0f857271aba5f97ab9dc189133e31e2a0526fa",
      "0xfb820293249a90126dd49bda4396bc542acbfe7f",
      "0x8f194df145ebe917160f33a2e8ee80f9920fb6f6",
      "0x5ff2a0a5d72d173442d4cd73528c1e9e67c98fbf",
      "0x7716b647e1b7b3c4de43776773544354449a499c",
      "0xa2a12dbacb94c47628abf040a9ec87d2eb7a2857",
      "0x092d79fdd0bc17d70863eb2206edde5a0bfdb997",
      "0x0dcd45892694f734a7035aac6a3f64a8ed30f4cd",
      "0x024a2612972bcfa7011b862ff0506fe1187c983b",
      "0xbba923e2ed47345c00621b2ec19c39d97b2fd4ad",
      "0x864ae9325c5090aca91bf70b0c54e5fd649e9c48",
      "0x7625f596220e67eca6c53dc313c60079ed58c343",
      "0x4d221904e1370dda7d62256379eabc010309dd07",
      "0x378aa79588ae14cad864434a31dc8404a0165dcd",
      "0x67e98602b5ad8bb6bf506a5b9593ac6c11d927f7",
      "0x51794f558825d7e3e148a04230345c1adde87825",
      "0x03605d57f72425c36b929ab82dd43bd3c8765ed7",
      "0xb2b7cf04a31d943fbf14ea4575112d9b3aa2d3e3",
      "0xfc689e98438a37d855fdc72eb10028a66b1d3a0a",
      "0xe3be8df2324a6566fcf77f9c756f20012679fc2c",
      "0x7f522989a221fd02fc60cc32faae4400c496729d",
      "0x2c0e5ec8794aeba837a894dff2c3a605a353e56e",
      "0x9b9ff8e92f29b357fd3a547c613877896c86643a",
      "0xa2dedd34e1c6331110a5b288018f35e0a10d1357",
      "0x8285253cdcd7ac274a908c0f2e53af2afb96c7ea",
      "0x64096773439e1ace82b5c95674ed342e0658b2b7",
      "0x7620145eee9f5fec39a58a93606d03696731d058",
      "0xea1b056043bb0b03451f40ef0ae8566409952284",
      "0x4c93dd4ccf048d2d1640a71b351f5310d784c6c3",
      "0x55fc9817f64939e60ac83ecb546fe28db3d4ca9e",
      "0x2cee2672bbcbdfa98696f0d6abc684c051473818",
      "0x42ee32354a3878fd0e2fad76bc7563083bf3f5fe",
      "0x2bf14479ea1a36600f699d02fa8faa70f1fe2728",
      "0x7dda8b1c5156980a06f65abec0c28849af81bfbd",
      "0x6fa8873a5c6e4d899e42ddb2ad767ee365a61e0f",
      "0x91752f5d0ec6d3032861941071dbe0bdc3de7361",
      "0x9fa3c87eb4668984b87e185b24ae08759b0f50bd",
      "0xac35ddfbdaeb568d165c2f78fed58f4bed734eab",
      "0xb98296c9b2d75c6c358cc9367acb4ff433f0a742",
      "0xe24a157fc29799a7e3417d27fee4da1f028d132b",
      "0x0a3d6d921c754b763ca47e73fb0e8234e380cdf4",
      "0x0e16fb3f3f839832fa31bc93301a9fa7833eb94a",
      "0x1407665ee916997ba7f15e29565e98c38fe41316",
      "0x34f39a005233164cf6ad9f87e355386ea4cb5061",
      "0x3a684e5382477ecc874d66b73e85653b1fb8c355",
      "0x4c813a3e9354c167442edec587c6ab69951bdd64",
      "0x52fd149dc04ee13c30947f680dcaef055bb49b0e",
      "0x605fbdd3d2e9ba4966222748332a4137ed2ddee9",
      "0x64811c0887951668324da17886a98a55cc5cd195",
      "0x69f34c600f58e833688c595929dda89a859e9863",
      "0x6e8c0dd97ab7070cb8fd661122d3d0532cc79d33",
      "0x737dc29bd8790982105a3040181779544c6fa3e4",
      "0x7e3a9a4d85b9c8e1cf23bcc9d1a8c709136eb5c7",
      "0x8e3a94630bbbfdb11de3888342c05b56f77cdf62",
      "0x91ecc71f843a9cb656d79d91f576fc78dff2a16f",
      "0x9be4fce8685dfed05e98382052a244b9573e8998",
      "0xa505009b25e646ce8e63f880b9fef025c18d4a7f",
      "0xa9af5143c8331c567efe8ff3e64b5dbdae2a959d",
      "0xb2817ed45f3a24962634a31d18a72022787a6c99",
      "0xb5dd16f777d836089de26e03c0f0e03da7b9698a",
      "0xbac5c05feacef303d742ba8e32365bc37c51dc2c",
      "0xca20216efce8b894f8473d7037646e1a68f840d0",
      "0xcd528ca3689f0f80bd3d45a4a438253b824ab8d7",
      "0xd4bbe225b6a92cdadc69301dd54c1cf0e437b659",
      "0xf7fa2b9ccee29d6d760377d040e6ef92bf9b09ff",
      "0x4cd15f3755ece14aac2843519e56369178851dfb",
      "0x67d361f4df24193c86dee5bdc1ba988575d0fb2d",
      "0x7c43362831e31bd6ada833de930f3b9ea9d76415",
      "0x828ca0a120addb9ae01d3abdbdd3c5cc7b364565",
      "0x87fa5f3353ee1a65b86efbc1a3a41ae00df00988",
      "0xb4a9f08e1addaa8ce1837e3c73093d2970aae7ea",
      "0xd480c4bec2cbbd09be7f365bb23a713ec0048d22",
      "0xe024c2a8bd5b085352133c193b11f54b4869b854",
      "0x0d5332e08fe7d983c06ec7be2bda643358b4a357",
      "0x1b9ed718ef1372d14fd15ee137884f20189b83d4",
      "0x1cfc2aa94ce44fcf5804e257745eb15c61bf2c6a",
      "0x2781c274c184a90bf89f1f379232d8e3ce3b1ecc",
      "0x37e2da12e26f193f77fcd8a225d9600f3769af7c",
      "0x384839eb9d0f4fd74db61ad063e11625cc58af2b",
      "0x397af6f6dbc200e84878ad4798a42fb005755259",
      "0x415ffa2532bda81e5a7905f30bd188e9ea70aabe",
      "0x4177be4d22aa87bb6bfbd9dd1ab21a223daf3af4",
      "0x47d3a1cdcf1bca75527748a0998e9742ca5d47ca",
      "0x56fc390081ca6d629f5e89ce18579f16d1d1b9fc",
      "0x607a2b222a3593a8dad86d07d92420938c2b03a4",
      "0x63f134562dfad5bc72f0f9c7101b2ef54b5444d5",
      "0x6c352b62cd85aaf02ab8116fd09b9abe18727a96",
      "0x7242611e446d8bf68325022608edbaee20e94423",
      "0x7d1cc017e3178b938090259573bde4ee701e6dd8",
      "0x80180e150dd0c8e58a079b995ff2bf9d48385168",
      "0xa1112524c8aadd762cd4d2a7b3119af3720813e3",
      "0xa48ba4c1aebbb4427bdd032a506ef5d5446f61d2",
      "0xa570142f8f8f44139f8a1bf3811339406d87c876",
      "0xa91f31360229bc6324ae7f11d0b08707315effd5",
      "0xad972ccea8da18016bc583fd8d1c3fe3f5cace88",
      "0xbb190045da11543f7d3589e40b68b41bb0e4562d",
      "0xc12774cd85e7d1919e436178299ff342a306a9e1",
      "0xc2c8e1af0741eff61d6b4a17c60de8488f559975",
      "0xc569e734ca57d7125fa69013c86c9b0b5bde4481",
      "0xc7062f13cc17a6ee5bef82194f088a77e2ec54f7",
      "0xd3cd8212871f5fd5f461ebd6ba113b6a8c50c61f",
      "0xd9e5c495eb09063f94bcfe8b513c3651d589eef9",
      "0xe02879200cddb0d71f6f931ebe2e5208bf489872",
      "0xe9b54ba35d4649d88e982629a4fb56a328638f07",
      "0xefb70e8b79c0b6bb01fd021eaafaa1e090997912",
      "0x6299d39364eaa203983812bd3c2e41f7c0290a44",
      "0x150fd019fd55120f687e9c65a7a7a91c57c9b5f8",
      "0x3ef24b90ffd591679599413b6af13bcc8d1929e5",
      "0xc6a7380a02de3e22f44932a0d2a09cf2015bdc94",
      "0x25817174e3bc0abe3ab09358905558881b722f96",
      "0x2ae9a70700a7e6cf7675af0ee50b7f6bb9271140",
      "0x2b7970bd023ca7e25068cce9742531c8225ccf32",
      "0x2e51d73eab031c7d0a081fd8ed27bed41579552a",
      "0x3424391f00304c5723db9c1ef761fa04759de9dd",
      "0x46242eedb4c17e20c509498eb786ed7fdbdc76ee",
      "0x60e57243dd4effc95d0cc140fd3de5f6c8385c38",
      "0x7a27ec3256520c6459408f767a2775ea697b8b91",
      "0x7b4561989ae1ab3543b8c224a37d2a2f75cee93c",
      "0x7f40ddf50c3a0a3d1ef4ce854ca50fdd63e863b8",
      "0xa242e846ce963268ddd3911279ad06bfd2ef73e3",
      "0xc869c4141235f5966c6f86c7abb6f817d01f73f9",
      "0xcc5b34901772d791069b069b5246222a62f7f16b",
      "0xce04ddab625f0837a6e346ce302888e2839980de",
      "0xd4e453677a089d6463b6359880b305d66d3f59c3",
      "0xea8f9f15b4aabb6b19a03400227a54b162c06769",
      "0xf4aaddb86ca7b8115342e7bca3d100b3e8d3c263",
      "0xfeb9c1da4305c10f1d6d64eb91c3001237d921f7",
      "0x40bf41dea71f9bb5cf6503f0b309fd919c455c3c",
      "0xc07fc1ea22b212ac109f951cebaac119ccbc8413",
      "0x56841d7f78fbd7560f5d6e4a593182af6ae1a7d7",
      "0x6535b2cf14697fa744a9900d687388efc87063d4",
      "0x334428490ccf5a54fad36e8217ff6cd091a30985",
      "0x4ed6eb35dbecab5e8866e1df189e8d2bfefe2a37",
      "0x01b39ff00286b9399cc12051462c5b2aa3adf0ac",
      "0x003b4ad3f9dead95a7995eebcbb8e1d3c0b45a09",
      "0x834c49c75dab5768df25b608d8814ed8408077c1",
      "0x2aed794fd50be9da6e4b7315bbcab77e0cf7e2fb",
      "0xcbb6deb52ddf80157e0bd8a62f572e4da62ea4b4",
      "0xad81af9a126d332cdac5dcd56b526e138a25ea79",
      "0xaacab518c7a9f606af89eea3fbe8f7cae126ab9c",
      "0x1e2cd4a00ba9abae4d9b0abef3d3f3ffb6911ae8",
      "0xf8ed34f81b620adde3cfd8ec5fb11449d43c7f71",
      "0xdace676fb25e0cf9fc5167c9f7851f0268ec63df",
      "0x65251ceb11a7abde4f31cfe90ae86e5d1815efcc",
      "0x134cb59872e260df11ddefc57b8da173cb7da7e6",
      "0x6ee6f5fc00173ffcd5d62fb571afa78a6f55b694",
      "0x2c89bb711f0f43ea8e8946bb394c64877fb95042",
      "0xc0a48a23084b63dedd7513ff7c7cbfa8a86682ff",
      "0x0372573bcc04589a0d8d7f527165f07d804568db",
      "0x2d01e93346a705364b02765bb530c30a2c6c39b5",
      "0x2aed794fd50be9da6e4b7315bbcab77e0cf7e2fb",
      "0x5761bd79640369d862ac9e29b12bca5ac21f4bcc",
      "0x77c4c0a1658bacc03ca9b3f266b4487697ccbac7",
      "0x498fae565586d12c661aaf64a19b65dd2fe3f8c9",
      "0x00285086d8dac2361eb3d3a9a98f689879e454c8",
      "0x017ef327bea9aed5580a82eb6e84eafe17a581c1",
      "0x01b39ff00286b9399cc12051462c5b2aa3adf0ac",
      "0x01d816dde1fa1064be2678c6cbe43ba4d562278e",
      "0x02d4346998d63a725d90c128fdc47d6eac8ab469",
      "0x0372573bcc04589a0d8d7f527165f07d804568db",
      "0x04dc9ee916a4f1bc55450e734cd079a0c4d1d66e",
      "0x0584df7ee832507dfc1963e3432707ddff47f1d5",
      "0x05a8db73fa0eef886976c52e03e043d5a21fd2b0",
      "0x066cac848aa77858818148b608fa82249e9a997f",
      "0x06e736c392e46ac2cb69009b42ee8df2af5c9bbc",
      "0x0863963bb8b6713f0d2a85eddbc540c225fdf037",
      "0x0953678ac2ee8571486ca9f94d3306d403cc76c0",
      "0x0ac5977912dfc419d00972c250666fb98c45de96",
      "0x0ad5f42c44cb1e8afaa116e963096de97bc7482e",
      "0x0be848e6db55564b186c6373e781c6a141eef067",
      "0x0d8df8fa57e0a5ae086206bb8fd34a6d522db951",
      "0x0d9720d462decc28c46c0db20d100cc824f6aa96",
      "0x0e5c657240daedfdc6fc9e564b1b98f333f79276",
      "0x0eac4689ec832305fc60d3eda84f90ce065bf612",
      "0x0efabafafc730290e4954af7fa9ad102bc72e414",
      "0x103bcb4330c9471ae890d0be1c0194c2c3b82cce",
      "0x11e4b17bbcf0fec0b2e554385d5f742349f38714",
      "0x11f1f206a69692955237c7ff492f851c40655c03",
      "0x1252439b37968572884c35fa6843bfe2c0b07845",
      "0x12fb886a3776f6b04fc0b27346f55a89f4918a06",
      "0x137729c75f50b8996aa4761eb889812ecec6623d",
      "0x137f63e80460776772cd571d63ee9e490ec1ab82",
      "0x14ba3e6b5a3abe6a778297434dbe45f0c47e12c7",
      "0x15bb9566515f245d7d6b177f109a03d37186776b",
      "0x1674d6af75afd9f18d0fc3a82d668514e044ee13",
      "0x17b2a60f6d7362acd23c7f241fbdf7fd2d6559f0",
      "0x17beae80236199c287e5871de9c92fe7a38092ce",
      "0x189769fbc9cf678345c4d1721a891095b7a3f52a",
      "0x195a7d6141717f60a7b09dedf52e49830e3510b3",
      "0x1b2f5f7756c8ea39e2e136da2250b39abb0f0e4b",
      "0x1bbf55970042fc7d1d66cb5658cabd30aa72e3ec",
      "0x1c64e1d4e9e367662e0c24d70c15e203ddedd2fe",
      "0x1d0b448a5c36d0d3885d8c2eb3f6f8888d5ced8f",
      "0x1e0b406e0dee1b5d5f6a02dd78ff2cfd0ef451d8",
      "0x1e2cd4a00ba9abae4d9b0abef3d3f3ffb6911ae8",
      "0x1e948ccdbb1879d976b48f48aec3810835b11086",
      "0x1eec2bf6c48b824f30d6b8dd7ba8f602cc609361",
      "0x2097b2eb3c151fb3035f9806024701b6aba0e52a",
      "0x20be5e7ddd4177067d9dc170da1f98cf8285d7ac",
      "0x215ef2486db74fa9d0c954e0d04834e44aa64e8c",
      "0x21f8d136e28ac2150e1f54fe050a460d3f80aa21",
      "0x25789e5f0f666e59614b02141734fed1957e4d12",
      "0x25a28de73c31e8f55192449d486b84b72888444e",
      "0x25a577a021ca9f2376d6487d99ab759c3c60a696",
      "0x25c0ac90e8400c51db389c60f16dc37c1caf23ba",
      "0x25e5bba1240ba58041d539026dedc1b3b1f2ce5a",
      "0x25ea7f96e115660a90dd42eb78dac595f79363a3",
      "0x263220d3f42e18121e84171e99719a1cf2526270",
      "0x27347ebf790eda89d1525a8c7f2122a4f3f52c74",
      "0x27bb026b47e1d8d2ae6c49eca78fb0f7d0e767fe",
      "0x28619e0741381c0987d31d372ceff74c33fc7610",
      "0x296fbfef57e9a65f236e6689985b92fe705ce0b7",
      "0x2ac9dd395b1cd8200cbc51bd55d0b2c185a650a4",
      "0x2c654d5083671326673819c8a5a5816c4845d90b",
      "0x2d7c8bcfba1f1241cdfb50254edf0e20ff18289e",
      "0x2dd39e9c19aa8dc6abf63a3d74732d2aee650061",
      "0x2ddfeff6e55acac893acf16a65e5509837eb6cd4",
      "0x2e48e1adc476e2d063e257c5e052cb02cdf3a125",
      "0x2ec777c25ced269841ffa9b43aa0adf17edcf897",
      "0x2fb8d70d158285cba24a8bd20f20a74dbba486fe",
      "0x3067421ccd1a67d875ad29055185742201363949",
      "0x334428490ccf5a54fad36e8217ff6cd091a30985",
      "0x33cc21937a4097ade79c0e49beeeeaab86219ce0",
      "0x348ff7a96e1a9fe74ae1f572e11d4335c61dbc25",
      "0x365ddc515f11d97f8984a6d874f28c66aa5673ba",
      "0x36792be329556eb06ce5bcb88293654542d3adba",
      "0x3e4aaf3450395f476ece5cf478b79ea95a4268ab",
      "0x3e6eefbab1d6583d8582dff90e3bdd91cf3313ba",
      "0x3f089f5849ae684b8c2900f053fb54d0872f0c06",
      "0x404da09fe3f9e5342367c31f84fc7bc23d7687f5",
      "0x411546a4a7c50a00047065283db9882b922eab41",
      "0x4344c106d1d5b1c9d51139d84c3e984dcd2c2003",
      "0x4410ae217843cddd031dca334c1dbf21da21f13f",
      "0x4423908fb8f929355cd069f676fe474b662db2b3",
      "0x453bae9d628e05078cb194be4c16270b279306b2",
      "0x483d98b4f0ad0abbe910ea1605eda41b01a6c5b6",
      "0x495624a0f21833b4b577d0d442b7207419a0f413",
      "0x4b9ce270beef32028bb9bbd36dff06daeb691de1",
      "0x4d98f0c208569356719ad8ce5005ae16adc406e1",
      "0x4ec2d22c17667997051e67f2cc7ee38368b3a237",
      "0x4ed6eb35dbecab5e8866e1df189e8d2bfefe2a37",
      "0x4f6a0b8e7ea0c3dd13901bbdf0441df21b6f89b1",
      "0x50241feb3b9d0ea53629c4055636ab5a86a51a07",
      "0x533adae20da72bab8a41aa93d4ecd23c3084575c",
      "0x54bf5e37904c797b4cf42c48cfbdeb903cef8a3c",
      "0x55d3f53928099774ce0aa3eb5b4e84c9aba87761",
      "0x5608a7201f5e09c159fa0128f002a7a87bff81be",
      "0x5673ffa83b46a545a7e698b6cd1346e568d0816f",
      "0x571814430440ae91d8f190b20d571f2871d0d421",
      "0x57a6825a072969e9082a56cfa6b0db840f79653c",
      "0x58f531c2ab1ee5e74469c567593b3a492a620cf8",
      "0x5ad857791a182028cfaa526d622ac779b6b2ce40",
      "0x5adc771018fd387221ff2d7860f22de38c26b280",
      "0x5b023903db466945e23308221dc835a83228da1e",
      "0x5c60da2d8bc8b8ae6089df7d22969cc354d61bc2",
      "0x5dbfc684924af071e1acdeff3c33ba3643e7988e",
      "0x5dd96f4e7bc99a1c47366c8f99628313f9c604b7",
      "0x5ea53576ec7916c1d4f633ae35e9aba3fc37305f",
      "0x5ec04bf3b216184eaccf6946edeccba0580f665a",
      "0x5ec62034dd3b198763c72e5ae775fad3990973b3",
      "0x5ecdbfa96615b6ce0f6c83c3ba170b2621ca5b2e",
      "0x5fb0231244f8e8d851329ac7bea1b74f6e4b4c1f",
      "0x60b6598823659f6cd452aeafa937cd0fcc38c74c",
      "0x60c1c80bc74637fde43823d75455b580be9c6aab",
      "0x62de6a6409ae0127a8eee69f19aac360b3375589",
      "0x63c1f36782c7188bdc6ecc8d2303bbf35f04f0d2",
      "0x6403f5fba6cc9d7f82366ebd4b646229841f182c",
      "0x64898b7cd5ff88da339d9829183c0192945e877a",
      "0x65a5c177cbc0a3b119c980bb643e0f28b73f49b3",
      "0x666e4ba213f1bc2c0e58e4d350258843b51d053e",
      "0x675e912a5e4ed7e3ab9c7a8597e3efe86deaa36c",
      "0x6855fb0c85f0ea87ea419c661628e64a68e8a91e",
      "0x6869cbe6301734b1c1a8422f17834a2e8f338b68",
      "0x6ba3d78ea19c5c701b520ee01e4967821570badd",
      "0x6c1e5f064b46a4cb444cf6265ca2d463bcc7fd7a",
      "0x6c42c30c87081a53abbfcd1d6adfc4816a371f30",
      "0x6c721ed35f3d11a22d51f0dcc8758045c32ebb81",
      "0x6d2dfbe057351c23035e7eea88a9f758393392e1",
      "0x6e89596eddd02e25be99e61a123585bf5be0f45e",
      "0x6f3fdcbbde872f2c76bbb35d05b376861593094f",
      "0x6fc249bccbf874c718aa19589bd039962b8c5f0f",
      "0x70e7c4c89d3751d0b7e8a60df8dcd5a69b71c93a",
      "0x7107ba1617b1445dedd1ab95593c1bbe74551330",
      "0x7303a8012e80d9b1c7725d1ecce0885ed222bd0e",
      "0x7372838169895900695e908736a52a443039c5be",
      "0x73786874d6062be2ae565571177e3524ba03c6f7",
      "0x73ad7d9a582000bbc66db08d8ad1a140f54b1158",
      "0x757c6e8c953b4d827fc3a33495abe6c8daf6ce7a",
      "0x75d43760e8993fdb89faf6c630e16d59a83c0d72",
      "0x7619af4d8407934a1e592e1a9e61eea008bb2658",
      "0x79e69b13139d2d6dde5bf5cfb1044123995120d6",
      "0x7c7fdeae405dc752e5d226312adf5d95dec7ceba",
      "0x7cac941ef36a79dbec26929329c3862fda337cd5",
      "0x7ddb7205dfe81d1cfe4c48c2274b44991b6f4494",
      "0x7e3d0861b08ea657a3f909e26df0d7e3bbb4e3cc",
      "0x80f31839bf87b4e0459199b3af3ac2002f25ec8f",
      "0x81379fa583abc6fdd00eecbb85cf3334fbfcd616",
      "0x8165fa047598758c799b3201542590dc03dbfbc2",
      "0x819d3959c07be37a12bae8dc51cdcb32f3a34133",
      "0x81c20dae3aa6dd92c8557746ad9ff4312f954777",
      "0x842bff7bcfbbe5ae3e7c28d8df43ef4883716691",
      "0x84920286cfd585a081bbc1e9a3f8968f5d78d62d",
      "0x852b70a5a024c65458aee449d03c70f83d55c4cf",
      "0x85ad8b024488d83f2eeef58a0c92a9176b7da2e5",
      "0x85d3215e32f7b01cec944145d1a2b713133b5285",
      "0x861fd5e04d770f2022341d4e8ccd1d79ee80b941",
      "0x865a5c5fb6b32e1f3facf741e564b2f0bb251e47",
      "0x868c026f3aabfb31f3845e06afee519ff4357dd7",
      "0x86c29f2847bd8a76c0d167cbc4f334b45756de95",
      "0x8978affe7fb0965846672f2aef1c5c9715c0f6b6",
      "0x8988e4f12247bac780158ce5bbc055603101d79c",
      "0x8a5663732f388e23b5d6a97ff3accf961a9eeedf",
      "0x8bdfa359be493618fdf7e653d0a76e931bab0089",
      "0x8e238be384720bc7a9f191111b34761dd457001a",
      "0x8e69e3047edf881772648ef7c9d03771123f96ce",
      "0x90325dc16afa2c06cca4d926017c6c5914477604",
      "0x91b6fc67f675192a98dc61885529dcdab180b0e2",
      "0x928ccdf0c6729585c63a84fbcde11f9d3871c1f3",
      "0x950596349137a2383c9dadcc99b96feeb1ab7fe9",
      "0x96f7d42da77f466fcb8454b5bf6130353821800b",
      "0x975ec76dd0870d164b8b8c372a66a9050fb61865",
      "0x97c8becdb6bdb1bfc0a789cc240f0e126c9a3feb",
      "0x9a15eb17f53e20b166998fc26e891d2ae9ce4edc",
      "0x9abde18b56fd65a05ca0f3795cf90eefa7437e7f",
      "0x9c147cb1728b69c8b67eef8c0bc836ee8529feec",
      "0x9e0568b7d69d3406ed106fda7c5c6f89e0fc5f90",
      "0x9e3d381facb08625952750561d2c350cff48dc62",
      "0x9f75e5ed7e6278a6a5da87ae9938bcac0fdc8c18",
      "0x9fa3c87eb4668984b87e185b24ae08759b0f50bd",
      "0x9fe1c222f67765d2c0cabe9b16b1e8688827963e",
      "0xa06624d7b5686435f12b8a2dd641340eb7761b9c",
      "0xa3cb294b8fcab83f0e6bc469347e501d309a1fe9",
      "0xa6089d352d35eea3d8a747ed5269f2ecad5fd030",
      "0xa7763cc0694d056f04359d13f64bec5f6745d092",
      "0xa7835dca5ed50692922a895309f2cee09e9facaa",
      "0xa91928376a351f132294e8eaa7665d33523bdab2",
      "0xa9dc5fdf7b42b92491851152a5d20a1120de9d1f",
      "0xaa9c05df4d0aad11fc864bcadd89b56b9c5e5264",
      "0xab7f01ad6cb0d5086f8bcf083523a1db0d93c244",
      "0xac6b420f49ef705b14d0d5f00441a1fd03baf122",
      "0xace142f57e9d681038e73076af29393a5b8784d6",
      "0xad44d11ef8019adc52f46443f0a27098ad086486",
      "0xaf4cdbbd068cb781b26a587e98616c90017b1054",
      "0xaf7b49329688bc5f0a8cdf97562f07fa825e4886",
      "0xb108097cd60ca7cdca9a9545b8c9c454cb4e669a",
      "0xb1fa9738a4898b8001bafa85ffcb507030aae36f",
      "0xb21cbcc8da00b4a665d90ecdc9af79af0cb0d633",
      "0xb3fc311bcb607a29bfc3443865c99ae891b29455",
      "0xb4da32e31c3090ba132c08224f5c0fe82a27959f",
      "0xb585481a0221136aa5d72dd8822d1db86c995f43",
      "0xb65a8896416420055d8453da3d3bf64da5a5d9f8",
      "0xb683b39ce89165631874a6142d878bb8238d1ae4",
      "0xb7160b7991529bc06806e603045760aba6d980b0",
      "0xb75e827aaf5c204f1e375b0d52e4a6d1ba0b94e5",
      "0xb82044882c713c3b5a135eaed1e745fa14ba61a8",
      "0xb915d1e50b237a1c3a12a1487f3510cddb466dd6",
      "0xb98296c9b2d75c6c358cc9367acb4ff433f0a742",
      "0xbaabe13864bed38d66e1a10316dc5b1878dec48f",
      "0xbb0af8fb13473b81c2b6fe1503a0f70207231717",
      "0xbb8b5c4413e733d01cad715f7fcbfa535ca00f1c",
      "0xbc1f10bdea37a0967eddfd0412386ed90bdf64ed",
      "0xbc8f7c4d25e7adf6a62d8c40163f185d7c563842",
      "0xbcc84dd5456fab41d2abe9d54c7a1abc8e74cd7e",
      "0xbcf35f9ce2873658aadcb2100d75b00ee5330640",
      "0xbd9bdd948073e96e92b1c44fb8ba3db6d9fdbbab",
      "0xbdce5bb27c93f70cafa9f260a250dd10cae5bf6e",
      "0xbe4ca71d2511d4dbfd8291c4d850dd1287ffe35f",
      "0xbfecb5bd1726afa7095f924374f3ce5d6375f24a",
      "0xc00825acdb6fdd60dd94685ba8d40a3bf692c902",
      "0xc0227b03b9ce64d915b8acc02dfaab62aff0b0b5",
      "0xc07fc1ea22b212ac109f951cebaac119ccbc8413",
      "0xc10e3eb46da0416808b289801f6af8dbf9b21207",
      "0xc1876bb98df09206a7929350e40eb0b970b2c05a",
      "0xc1d217989565ecdc634af32193e951b4e8490998",
      "0xc24976499079dfe6b38e555717aa9145451b036c",
      "0xc2eacfbb2fe0064523758687fe3dde3baa76de4c",
      "0xc300615bc4092c1ca7fa6bc21531522cad1e6105",
      "0xc3689b375a6371939c4a70747aea75676d5ef074",
      "0xc3b52d51b493b5da4d51627929f4fc38fd51eaba",
      "0xc3d0b973e7e10860dce38759dd6138abd3aef243",
      "0xc5286a6f0d907a34b8ce58c6dca5040559447150",
      "0xc646cb51295519a2a16b736e0ef4efb3b2363a8a",
      "0xc7062f13cc17a6ee5bef82194f088a77e2ec54f7",
      "0xc70c465f5b835caf3f97a16b9302841ef623eb27",
      "0xc7b1192f82b161afcb2f12e662fd3e646d42b763",
      "0xc8803cb80de6e8becc29c0dfa613060742e0d7c1",
      "0xca28058bacd0c5bc67e082932da9ba0173725ac8",
      "0xcaca8477e84fe1509420b2e24790c5e4047bc605",
      "0xcb0e27186c972bec823ad098f97519bc9570b498",
      "0xcb1257e4f2efd5ddab72a57e76a0bdd68211c1e7",
      "0xcbb6deb52ddf80157e0bd8a62f572e4da62ea4b4",
      "0xcbf6f50238f46313bdf750b2e1c3ffb4d910a087",
      "0xcc8bf492061a184bbb1e3870be48e86ac8fb0e2d",
      "0xccd424ab936f8158153a4e1a484eb1b06c83dabc",
      "0xcd11abbc370dbce80b81a250df87b3226f2b1a49",
      "0xcd30a858be430103e1764d5dfb0b3ee89763b0cf",
      "0xcd3125ee820f47759bf2e151546b664cd597c329",
      "0xcddc46405c6db56566b088e308a157ba3bcf0b4e",
      "0xcdfbe633e6720e847879428137605e4d605f2018",
      "0xcf0ce62143dfa0420db74885fa30f5942b0068c8",
      "0xcf6069c99108b45c094d65e5016537fdc1bf6c14",
      "0xcf6978eb670d6994f74729aeccfbc0539030a45b",
      "0xd090513900ca317a41346c3b7be2ab30615d120c",
      "0xd0a17cfdd5f474adf181b0bb3e906f27bca2cb40",
      "0xd112071a5837ac1f69e40fac7a25caaa9baafbcd",
      "0xd18a67f82bf9289cfa8067852026c1f93126f502",
      "0xd1e2fec054b84a7f501818c7849817dd3065610d",
      "0xd221b8efad2e3f2890ce1937a0294150d1f26b00",
      "0xd2385aae503e65a84e44ead0708bd87123b53cb7",
      "0xd5bd8a108c27030613ebe189e781f45258e79894",
      "0xd74fec4f72a65b2e29d2ecaff2c28ffedaed00fb",
      "0xd7f5e70451965877a3c8841f202c3acd7df1b335",
      "0xd814d2ad9aa62b74f29ff80267b3b5e9f18e6085",
      "0xd84194d6f34886439714037ae0d140f385e1a05d",
      "0xd88b2b20bd378a9e9d209bcce52bc614f3824858",
      "0xd98d839275cf356ec9e34a146c7edaaa69f29022",
      "0xda3b585334fe711c817bd35a7b7b03ef9fda7d97",
      "0xdbd8c5f71915a44f4959e3fb86b6db929b4f93e7",
      "0xdd9d8407f632287566f6a1b41a7bd2d94cfd4afc",
      "0xde7a619b032a3eeba16f6e20b4440320dd02b437",
      "0xdea208c78672f3ab31deb040b7f08503081af46a",
      "0xe0f9fc507245fe25279b88837c897e7b11a0d846",
      "0xe24a157fc29799a7e3417d27fee4da1f028d132b",
      "0xe2b7a01d7d752978ca63869557a24d3b5aa7cabf",
      "0xe540fea63ecab079f89d73bfb85e1a4a8752c377",
      "0xe8ed81bd9d70c8a2622b0302592950baa5ab45a3",
      "0xe99bc7f2e19e904a77ddb68ce3fb7c3b76b40ea5",
      "0xebdff89f1e1ee0489a1b1b5d224863128b4b16b8",
      "0xeff30bf864d6616d4e35b924d602ca13546c1af8",
      "0xf0c7a1d04ad223e7ea7f9cdc0a993d4989ecbfbd",
      "0xf1cab5e2fa0a539a06db5f62a0b03e4120193c9e",
      "0xf8dd394c783cc543140b31ea36a846694c829391",
      "0xf8ed34f81b620adde3cfd8ec5fb11449d43c7f71",
      "0xf98b25a81630d2818d751595ba652d536fd18b09",
      "0xfa392b66ec881cabda4a472762edc77a070eefe1",
      "0xfc5f6ed53b01cb4026370ca5a9a927789e3844e2",
      "0xfd4640d4fb229bd607df0b53d59075160deddb4e",
      "0xfde5356e4ba7ac31705157ce66dd50fa35f3a83e",
      "0xfe077390e785fb9d31bf1f7c0daaf44e22959e6e",
      "0xfe5d92e38e9c73329c3ff1fcf683fad581c4820c",
      "0xe29c6435059791e7a61e986310d0a9d4f8c2b140",
      "0xf02e559b48146227805320867d91b89a8e7a8e77",
      "0xa533b36f52e3f67d9e8aa330a775d512d9994443",
      "0x42c2a05acb339a5184bec5e6231b49e6b958c4f9",
      "0x0c05bc8bb184b29a04db4872f0784cc62bd0cb61",
      "0x729166e9ab9c63c276461c26fe20c41af96620b6",
      "0x910cef6974f9007d6d2ab48525285f74b9f32c4a",
      "0xc0b1b236171da3330d293c86625dd3a0967e9ca1",
      "0x7968f3e3609ee7b160fd0792d316558bc29be8d0",
      "0x40bf41dea71f9bb5cf6503f0b309fd919c455c3c",
      "0xfe7c4cb471140df33b60c7e921708612dec44794",
      "0x4351ca1114cf78af5712efe67ce4df8aa04e5249",
      "0x69dcd5b254534e03814683958bc15326c9aeca72",
      "0xa6adbcdf4b1c4fc440f3cb6c035fa76b41ed6c5b",
      "0x6507b7b1f9dab72da60399e3a76d7b06fcea2b98",
      "0xffc12e48ee45573607b1d5f39492bf35f2db053e",
      "0xb1bef758d3ae913eed3d5d3433e848c1aee3ae4a",
      "0x5450677f8a8fc669d2476a6cdea9bb6a9d475721",
      "0x8af5080f336e978b2987fced83f51e8f169e28bf",
      "0x4c554f9914f9ffe2bfa44b473b8caeaeb4750861",
      "0x483afda5eece4ffdd95e83531733bc93d82e003a",
      "0x0b167add50633c27d6a9a57f3c2d537c6efeabf3",
      "0x1ba57cc9e45f37f52194f35caf857163d20bf296",
      "0x1da83774a0b5b3fe679c1da028bc4719f0966bd7",
      "0x819fbb2d9880d23792fd9d1cde4c153bf9956344",
      "0x91ee726ee03b56d276cd87ae0bbb7399269d35ec",
      "0x506cc2f31d7ac86f60fd015790c31cbd93cba840",
      "0x91401918cb5c03d727731b81d0f92e4fc6ac97db",
      "0xff65049a1d9ab2c8ad4c7d2a6a18897a070c1f50",
      "0x2a9a03dbf0fd342c0132ce935de2974e6a45db67",
      "0xed54b7724ddf94fa94070a464fa048679e5b17d9",
      "0xd7599a1a044fa544e335b08167ecc03bfcc3e971",
      "0xfcbf0ddff4d1034af94396e6e749ed7b40fe0a58",
      "0xca590c80102dba8128404fcc18581893b3555dbb",
      "0x12756b8b90d03ecc9afdb08fe3e4a6a95f50f125",
      "0x56226ae66c07a7f038b0c6f215e7cdc82e325c66",
      "0xfd266d6ebe65e9ba5f13572e0e0f3948a674249a",
      "0xe911b64bd8e2106bb91acd31cf6bc5cb72398486",
      "0x5761bd79640369d862ac9e29b12bca5ac21f4bcc",
      "0x10a74d536d07baab67b4537d59a943205861ec31",
      "0xdba0b4963791c24448f33349e4a997cd46f21f8b",
      "0x63716d3141f7711402f3a99d2e9a645b229b8de2",
      "0x53e1d7acbec2f8f20c774b09957780ec1b8b86aa",
      "0x7a96510cbdd0809d9e2d3166bc9e50eeefb894d3",
      "0xd45f27404efc2b96e95aaacaae487dc0e771a28e",
      "0xc689f19d2205bf82f9d1f4296c5b999344b9861d",
      "0x3ad0c6c9256330b63af029e35289edc81af1e5f8",
      "0xc4c6ed8a22306bc3491d6f5e7a536abe2ca9e003",
      "0x35cceffd13a834128429349052da49875821318c",
      "0x10af3c0b921684a1c335ce4ec35c3deff339d1a1"];
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String((cost * mintAmount));
    let totalGasLimit = String((gasLimit * mintAmount));

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    // const x = new String((blockchain.account));

    // if (usersArray.includes(x.toString()) != true) {

    //   console.log(String(blockchain.account), "blockchain");
    //   alert("you are not whitelisted, please talk to us on discord if this is a mistake");
    //   setFeedback("Sorry, something went wrong please try again later.");
    //   setClaimingNft(false);
    //   return;
    // };

    let contract_abi = [
      {
        "inputs": [
          {
            "internalType": "string",
            "name": "name_",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "symbol_",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "_initBaseURI",
            "type": "string"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "inputs": [],
        "name": "ApprovalCallerNotOwnerNorApproved",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "ApprovalQueryForNonexistentToken",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "ApproveToCaller",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "BalanceQueryForZeroAddress",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "MintERC2309QuantityExceedsLimit",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "MintToZeroAddress",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "MintZeroQuantity",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "OwnerQueryForNonexistentToken",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "OwnershipNotInitializedForExtraData",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "TransferCallerNotOwnerNorApproved",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "TransferFromIncorrectOwner",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "TransferToNonERC721ReceiverImplementer",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "TransferToZeroAddress",
        "type": "error"
      },
      {
        "inputs": [],
        "name": "URIQueryForNonexistentToken",
        "type": "error"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "approved",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "Approval",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "operator",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "bool",
            "name": "approved",
            "type": "bool"
          }
        ],
        "name": "ApprovalForAll",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "uint256",
            "name": "fromTokenId",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "toTokenId",
            "type": "uint256"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
          }
        ],
        "name": "ConsecutiveTransfer",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_to",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_value",
            "type": "uint256"
          }
        ],
        "name": "Payout",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "Transfer",
        "type": "event"
      },
      {
        "inputs": [
          {
            "internalType": "address[699]",
            "name": "_users",
            "type": "address[699]"
          }
        ],
        "name": "add699Whitelists",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          }
        ],
        "name": "balanceOf",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "baseExtension",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "baseURI",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "bool",
            "name": "approvalCheck",
            "type": "bool"
          }
        ],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "cost",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "exists",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "getApproved",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          }
        ],
        "name": "getAux",
        "outputs": [
          {
            "internalType": "uint64",
            "name": "",
            "type": "uint64"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getBaseURI",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "index",
            "type": "uint256"
          }
        ],
        "name": "getOwnershipAt",
        "outputs": [
          {
            "components": [
              {
                "internalType": "address",
                "name": "addr",
                "type": "address"
              },
              {
                "internalType": "uint64",
                "name": "startTimestamp",
                "type": "uint64"
              },
              {
                "internalType": "bool",
                "name": "burned",
                "type": "bool"
              },
              {
                "internalType": "uint24",
                "name": "extraData",
                "type": "uint24"
              }
            ],
            "internalType": "struct IERC721A.TokenOwnership",
            "name": "",
            "type": "tuple"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "index",
            "type": "uint256"
          }
        ],
        "name": "getOwnershipOf",
        "outputs": [
          {
            "components": [
              {
                "internalType": "address",
                "name": "addr",
                "type": "address"
              },
              {
                "internalType": "uint64",
                "name": "startTimestamp",
                "type": "uint64"
              },
              {
                "internalType": "bool",
                "name": "burned",
                "type": "bool"
              },
              {
                "internalType": "uint24",
                "name": "extraData",
                "type": "uint24"
              }
            ],
            "internalType": "struct IERC721A.TokenOwnership",
            "name": "",
            "type": "tuple"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "index",
            "type": "uint256"
          }
        ],
        "name": "initializeOwnershipAt",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "operator",
            "type": "address"
          }
        ],
        "name": "isApprovedForAll",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "maxMintAmount",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "maxSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "name",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "nextTokenId",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          }
        ],
        "name": "numberMinted",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "ownerOf",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "paused",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "presaleCode",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "quantity",
            "type": "uint256"
          }
        ],
        "name": "safeMint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "quantity",
            "type": "uint256"
          }
        ],
        "name": "safeMintPresale",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "bytes",
            "name": "_data",
            "type": "bytes"
          }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "operator",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "approved",
            "type": "bool"
          }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "internalType": "uint64",
            "name": "aux",
            "type": "uint64"
          }
        ],
        "name": "setAux",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "string",
            "name": "_newBaseURI",
            "type": "string"
          }
        ],
        "name": "setBaseURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bool",
            "name": "_pauseBool",
            "type": "bool"
          }
        ],
        "name": "setPause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "name": "shareholders",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "share",
            "type": "uint256"
          },
          {
            "internalType": "address payable",
            "name": "shareholder_address",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bytes4",
            "name": "interfaceId",
            "type": "bytes4"
          }
        ],
        "name": "supportsInterface",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "symbol",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "x",
            "type": "uint256"
          }
        ],
        "name": "toString",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "tokenURI",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalBurned",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalMinted",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "whitelistCost",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "whitelisted",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      }
    ];

    // if (blockchain.smartContract == null) {
    //   const getConfig = async () => {
    //     const provider = new WalletConnectProvider({
    //       infuraId: "4fd3dd38c4474553b33ded0ddd28a0da", // Required
    //       qrcodeModalOptions: {
    //         desktopLinks: [
    //           'ledger',
    //           'metamask',
    //           'wallet',
    //           'wallet 3',
    //           'secuX',
    //           'ambire',
    //           'wallet3',
    //           'apolloX',
    //           'zerion',
    //           'sequence',
    //           'punkWallet',
    //           'kryptoGO',
    //           'nft',
    //           'riceWallet',
    //           'vision',
    //           'keyring'
    //         ],
    //         mobileLinks: [
    //           "rainbow",
    //           "metamask",
    //           "argent",
    //           "trust",
    //           "imtoken",
    //           "pillar",
    //         ],
    //       }
    //     });

    //     //  Enable session (triggers QR Code modal)
    //     await provider.enable();
    //     let web3 = new Web3(provider);
    //     const NameContract = web3.eth.Contract(contract_abi, "0xc83Cf1694bc20b917C969FB4A2571b7d8ef625Aa");
    //     console.log(NameContract.methods.totalSupply().call(), "hellooo");
    //   };
    //   getConfig();
    //   return;
    // }


    blockchain.smartContract.methods
      //change params in mint to number of mints first, then the signature
      .safeMint(blockchain.account, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
      console.log(blockchain.account, "helloMethods");
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 50) {
      newMintAmount = 50;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);

  };

  const getPrice = async () => {
    const priceResponse = await fetch("https://api.etherscan.io/api?module=stats&action=ethprice&apikey=HXZZM4ESINEDGTYQS4Z17DMG54QCV11M45", {
      headers: {
        Accept: "*/*",
      },
    });
    let price = await priceResponse.json();
    let ethPrice = price.result.ethusd
    let calcPrice = (250 / (1330 * .885) * 1000000000000000000);
    setEthPriceGBP(calcPrice);
    let calc2price = (250 / (1330 * .885));
    setCrossPrice(calc2price);
    console.log(calcPrice, "testPrice");
    console.log(ethPriceGBP);
  };




  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
    getPrice();
  }, [blockchain.account]);


  return (
    <s.Screen>
      {/*-------------------------ABSOLUTE OBJECTS BELOW---------------------------- */}
      <div className="spinner">
        <div className="logocontainer2" style={{ marginBottom: "25px", fontSize: "60px", fontFamily: "Orbitron", color: "white" }}>
          BenNFT
        </div>
        <div className="sk-cube-grid">
          <div className="sk-cube sk-cube1"></div>
          <div className="sk-cube sk-cube2"></div>
          <div className="sk-cube sk-cube3"></div>
          <div className="sk-cube sk-cube4"></div>
          <div className="sk-cube sk-cube5"></div>
          <div className="sk-cube sk-cube6"></div>
          <div className="sk-cube sk-cube7"></div>
          <div className="sk-cube sk-cube8"></div>
          <div className="sk-cube sk-cube9"></div>
        </div>
      </div>

      <div className="navContainer">
        <div className="nav-header-hide">
          <div className="logocontainer">

            <h1 className="ml14" style={{ display: "flex", justifyContent: "center" }}>
              <span className="text-wrapper">
                <span className="letters">BenNFT</span>
                <span className="line"></span>
              </span>
            </h1>

          </div>
          <div className="site-links">
            <div className="site-link1">
              <a href="#about">About</a>
            </div>
            <div className="site-link1">
              <a href="#utility">Utility</a>
            </div>
            <div className="site-link1">
              <a href="#buy">Buy Now</a>
            </div>
            <div className="site-link1">
              <a href="#faq">FAQ</a>
            </div>
            <div className="site-link1">
              <a href="https://bennft.myshopify.com/">Store</a>
            </div>
          </div>
          <div className="socials">
            <a href="https://www.instagram.com/BenNFTofficial/">
              <div className="social-link1">

              </div>
            </a>
            <a href="https://twitter.com/BenNFTofficial">
              <div className="social-link2">

              </div>
            </a>
            <a href="https://discord.com/invite/bennft">
              <div className="social-link3">

              </div>
            </a>
          </div>
          <div className="connectWallet" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {blockchain.account === "" ||
              blockchain.smartContract === null ? null : (<p style={{ fontFamily: "Open Sans" }}>{truncate(blockchain.account, 5)}</p>)}
            <button className="button-49"
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
              }}
            >
              CONNECT WALLET
            </button>
          </div>
        </div>
      </div>


      {/*-------------------------ABSOLUTE OBJECTS ABOVE---------------------------- */}
      <div className="item1" id="item1">
        <div className="open">
          <span className="cls" style={{ zIndex: "1000" }}></span>
          <span>
            {/* <div className="socials mobileSocial" >
            <a href="https://www.instagram.com/BenNFTofficial/">
              <div className="social-link1">

              </div>
            </a>
            <a href="https://twitter.com/BenNFTofficial">
              <div className="social-link2">

              </div>
            </a>
            <a href="https://discord.com/BenNFT">
              <div className="social-link3">

              </div>
            </a>
          </div> */}
            <ul className="sub-menu ">
              <li>
                <a href="#about" title="about">About</a>
              </li>
              <li>
                <a href="#utility" title="skills">Utilities</a>
              </li>
              <li>
                <a href="#faq" title="jobs">FAQ</a>
              </li>
              <li>
                <a href="#buy" title="contact">Buy Now</a>
              </li>
              <li>
                <a href="https://bennft.myshopify.com/" title="contact">Store</a>
              </li>
            </ul>
          </span>
          <span className="cls"></span>
        </div>
        <div className="heroContainer">
          <div className="heroHeader">
            <h1 style={{ textAlign: "center", letterSpacing: ".2rem", lineHeight: "1.2", fontSize: "2.3rem" }}>Build a Legacy With<br />Conor Benn</h1>
          </div>
          <div className="heroSubText" style={{ marginTop: "35px", textAlign: "center", fontSize: "1.1rem", width: "100%", lineHeight: "1.2", display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "column" }}>
            <p id="heroSubText" style={{ width: "70%", letterSpacing: ".1rem", lineHeight: "1.2", fontFamily: "Open Sans" }}>Connecting Conor to his fans, BenNFT grants you unrivalled utility and exclusive access to his career.</p>
            <div id="heroBtnContainer" style={{ width: "40%", padding: "10px 20px", marginTop: "25px" }}>
              <a href="#item4" style={{ height: "40px" }}>
                <button id="mintHeroBtn" style={{ width: "100%", padding: "10px 20px", background: "rgba(255, 227, 0, 0.08)", border: "solid 1px #ffe300", borderRadius: "15px", color: "#cacaca", fontSize: "23px", fontFamily: "Open Sans", fontWeight: "600" }}>Buy Now!</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="item2">
        <div className="heroHeader2" id="about" >
          <h1 style={{ textAlign: "left", marginBottom: "1vh", fontWeight: "800", fontSize: "2.3rem", }}>What is BenNFT?</h1>
          {/* <div className="blueStripe2" style={{ backgroundPosition: "top top" }}>

          </div> */}
        </div>
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }} >
          <p className="heroSubText2" style={{ textShadow: "0px 1px 15px #000", fontFamily: "Open Sans", lineHeight: "1.5" }}>
            BenNFT is a 3D NFT project by undefeated professional boxer <span style={{ color: "#ffe300" }}>Conor Benn.</span> Giving back to
            Conor's fans, BenNFT grants holders <span style={{ color: "#ffe300" }}>exclusive access to his career, </span>and allows them to build a
            legacy alongside The Destroyer himself.<br /><br />
            BenNFT is a collection of <b style={{ textDecoration: "underline", color: "#ffe300" }}>5,555 NFTs</b> that utilises blockchain technology to give you unmatched
            opportunities and benefits. <br /><br />
            Including but not limited to: Fight Tickets, Press Conference Access,
            Meet & Greets, Live Training Sessions, Signed Memorabilia, 1-on-1 Calls, Virtual Hangouts, and
            Behind-The-Scenes access to Conor’s life.
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", width: "100%" }} >
              <img className="nftImage1" src={nftImage1}></img>
              <img className="nftImage2" src={nftImage2}></img>
            </div>
          </p>

        </div>
      </div>
      <div id="item3">
        <div className="cardgrid_container" id="utility">
          <h1 className="heroHeader" style={{ textAlign: "center", fontSize: "2.3rem", margin: "50px 25px" }}>Connecting Conor To His Fans </h1>
          {/* <p className="heroSubText" style={{ textAlign: "center", fontSize: "1.2rem", margin: "17.5px 25px" }}>-Exclusive access to his career-</p> */}
          <ul className="cards">
            <li className="cards_item">
              <div className="card">
                <div className="card_image"><img src={ticket} /></div>
                <div className="card_content">
                  <h2 className="card_title">Fight Tickets</h2>
                  <p className="card_text">Ringside and standard seats for NFT holders. </p>
                </div>
              </div>
            </li>
            <li className="cards_item">
              <div className="card">
                <div className="card_image"><img src={press} /></div>
                <div className="card_content">
                  <h2 className="card_title">Press Conferences</h2>
                  <p className="card_text">Exclusive access to upcoming fight press conferences. </p>
                </div>
              </div>
            </li>
            <li className="cards_item">
              <div className="card">
                <div className="card_image"><img src={shake} /></div>
                <div className="card_content">
                  <h2 className="card_title">Meet & Greet</h2>
                  <p className="card_text">A meet and greet with Conor Benn for 5 people. </p>
                </div>
              </div>
            </li>
            <li className="cards_item">
              <div className="card">
                <div className="card_image"><img src={sessions} /></div>
                <div className="card_content">
                  <h2 className="card_title">Live Training Sessions</h2>
                  <p className="card_text">Behind the scenes access to a Conor Benn Training session. </p>
                </div>
              </div>
            </li>

            <li className="cards_item">
              <div className="card">
                <div className="card_image"><img src={boxingShorts} /></div>
                <div className="card_content">
                  <h2 className="card_title">Signed Memorabilia</h2>
                  <p className="card_text">Exclusive Merch only available to token holders</p>
                </div>
              </div>
            </li>
            <li className="cards_item">
              <div className="card">
                <div className="card_image"><img src={plotter} /></div>
                <div className="card_content">
                  <h2 className="card_title">Art Prints</h2>
                  <p className="card_text">50 Limited Edition Art Prints up for grabs from the world famous artists, The Connor Brothers</p>
                </div>
              </div>
            </li>
            <li className="cards_item">
              <div className="card">
                <div className="card_image"><img src={robe} /></div>
                <div className="card_content">
                  <h2 className="card_title">Robe Giveaway</h2>
                  <p className="card_text">1 lucky token holder will win Conor’s actual ring walk robe from an upcoming fight</p>
                </div>
              </div>
            </li>
            <li className="cards_item">
              <div className="card">
                <div className="card_image"><img src={boxingGloves} /></div>
                <div className="card_content">
                  <h2 className="card_title">B.T.S. Access to Conor's Life</h2>
                  <p className="card_text">Get exclusive access to Conor's career and personal life.</p>
                </div>
              </div>
            </li>
            <li className="cards_item">
              <div className="card">
                <div className="card_image"><img src={boxingRing} /></div>
                <div className="card_content">
                  <h2 className="card_title">IRL Events</h2>
                  <p className="card_text">Special experiences, Q&A sessions & more only for Holders.</p>
                </div>
              </div>
            </li>
            <li className="cards_item">
              <div className="card">
                <div className="card_image"><img src={boxingShorts} /></div>
                <div className="card_content">
                  <h2 className="card_title">Monthly Prizes</h2>
                  <p className="card_text">Cash prizes and other surprises exclusively for BenNFT holders, every month.</p>
                </div>
              </div>
            </li>
          </ul>
        </div>



      </div>




      <div id="item4">


        <div className="heroHeader" id="buy" >
          {/* <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 50,
                    fontWeight: "bold",
                    color: "#F3164A",
                  }}
                >
                  <span style={{ color: "white", fontSize: "15px", lineHeight: "1" }}>*mint data not accurate until wallet is connected, we're at over 1400*</span> <br />
                  {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                </s.TextTitle> */}

          <>
            <h1 style={{ textAlign: "center", marginBottom: "10vh", fontWeight: "800", fontFamily: "Orbitron", fontSize: "2.3rem", color: "#ffe300" }}>Buy Here</h1>
            <div className="supplyContainer">

              <div className="supplyLeft">
                Supply
              </div>
              <div className="supplyRight">
                {CONFIG.MAX_SUPPLY}
              </div>
            </div>

            <div className="quantityContainer">
              <div className="quantityLeft">
                Price
              </div>
              <div className="quantityRight">
                £250
              </div>
            </div>
            {blockchain.account === "" ||
              blockchain.smartContract === null ? (
              <s.Container ai={"center"} jc={"center"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    fontFamily: "Open Sans"
                  }}
                >
                  Error: Connect to the {CONFIG.NETWORK.NAME} network
                </s.TextDescription>
                {/* <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT
                      </StyledButton> */}
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "#cacaca",
                    padding: "15px 40px",
                    borderRadius: "30px",
                    fontSize: "24px",
                    fontWeight: "800",
                    fontFamily: "Orbitron"
                  }}
                >
                  Quantity
                </s.TextDescription>

                <s.Container ai={"center"} jc={"center"} fd={"row"}>

                  <StyledRoundButton
                    style={{
                      background: "#cacaca",
                      color: "#000",
                      fontSize: "40px",
                      transform: "scale(1.2)",
                      border: "none",
                      fontWeight: "600",
                      padding: "0px 3px 3px 0px",
                    }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                  >
                    -
                  </StyledRoundButton>
                  <s.SpacerMedium />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "#000",
                      background: "#cacaca",
                      padding: "0 40px",
                      borderRadius: "30px",
                      fontSize: "34px",
                      fontWeight: "800",
                    }}
                  >
                    {mintAmount}
                  </s.TextDescription>
                  <s.SpacerMedium />
                  <StyledRoundButton
                    style={{
                      lineHeight: 0.4,
                      background: "#cacaca",
                      color: "#000",
                      fontSize: "40px",
                      transform: "scale(1.2)",
                      border: "none",
                      fontWeight: "600",
                    }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                  >
                    +
                  </StyledRoundButton>
                </s.Container>
                <s.Container ai={"center"} jc={"center"} fd={"row"} style={{ marginTop: "25px" }}>
                  <StyledButton id="buyButton1"
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!blockchain) {
                        dispatch(connect());
                        getData();
                        return;
                      }
                      getPrice();
                      claimNFTs();
                      getData();
                    }}
                  >
                    {claimingNft ? "MINTING" : "Buy With Wallet"}
                  </StyledButton>

                  <CrossmintPayButton id="buyButton2" style={{ borderRadius: "30px", color: "#cacaca" }}
                    collectionTitle="BenNFT"
                    collectionDescription="Connecting Conor and his fans."
                    collectionPhoto="https://i.ibb.co/stVxNqh/unknown-7.png"
                    clientId="17502992-1cfc-4f1d-ba0c-2d9831fb4cb7"
                    mintConfig={{ "type": "erc-721", "totalPrice": JSON.stringify(mintAmount * .218), "quantity": mintAmount }}
                  />
                </s.Container>
                <div className="fancy" style={{ margin: "30px 25px" }}>
                  <span>Ends In:</span>
                </div>
                <div id="countdown"></div>

                {blockchain.errorMsg !== "" ? (
                  <>

                  </>
                ) : null}
              </s.Container>
            ) : (
              <>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "#cacaca",
                    padding: "15px 40px",
                    borderRadius: "30px",
                    fontSize: "24px",
                    fontWeight: "800",
                    fontFamily: "Orbitron"
                  }}
                >
                  Quantity
                </s.TextDescription>
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledRoundButton
                    style={{
                      background: "#cacaca",
                      color: "#000",
                      fontSize: "40px",
                      transform: "scale(1.2)",
                      border: "none",
                      fontWeight: "600",
                      padding: "0px 3px 3px 0px",
                    }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                  >
                    -
                  </StyledRoundButton>
                  <s.SpacerMedium />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "#000",
                      background: "#cacaca",
                      padding: "0 40px",
                      borderRadius: "30px",
                      fontSize: "34px",
                      fontWeight: "800",
                    }}
                  >
                    {mintAmount}
                  </s.TextDescription>
                  <s.SpacerMedium />
                  <StyledRoundButton
                    style={{
                      lineHeight: 0.4,
                      background: "#cacaca",
                      color: "#000",
                      fontSize: "40px",
                      transform: "scale(1.2)",
                      border: "none",
                      fontWeight: "600",
                    }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                  >
                    +
                  </StyledRoundButton>
                </s.Container>
                <s.Container ai={"center"} jc={"center"} fd={"row"} style={{ marginTop: "25px" }}>
                  <StyledButton id="buyButton1"
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      getPrice();
                      claimNFTs();
                      getData();
                    }}
                  >
                    {claimingNft ? "MINTING" : "Buy With Wallet"}
                  </StyledButton>

                  <CrossmintPayButton id="buyButton2" style={{ borderRadius: "30px" }}
                    collectionTitle="BenNFT"
                    collectionDescription="Connecting Conor and his fans."
                    collectionPhoto="https://i.ibb.co/stVxNqh/unknown-7.png"
                    clientId="17502992-1cfc-4f1d-ba0c-2d9831fb4cb7"
                    mintConfig={{ "type": "erc-721", "totalPrice": JSON.stringify(mintAmount * .218), "quantity": mintAmount }}
                  />

                </s.Container>
                <div className="fancy" style={{ margin: "30px 25px" }}>
                  <span>Ends In:</span>
                </div>
                <div id="countdown"></div>
              </>
            )}

          </>

        </div>


      </div>
      <div id="faq">
        <div className="heroHeader" style={{ textAlign: "center", margin: "0px" }} >
          <h1 style={{ textAlign: "center", marginTop: "50px", fontSize: "2.3rem" }}>FAQ:</h1>

        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ marginBottom: "50px" }} id="accordionContainer">

            <button className="accordion">What is an NFT? And why use them for this?</button>
            <div className="panel">
              <p>NFTs, simply put, are an integral part of ownership verification for the distributed internet known as the blockchain.
                They can be bought and sold based on what people are willing to pay for the value locked into them, that can be: membership, giveaways, PFPs, purchases, collectables, anything!
                <br /><br />
                We're using NFTs and other blockchain technologies because the capability to give back to the community we build, verify giveaways, and otherwise be transparent in what we're building here at <i>BenNFT</i>.
              </p>
            </div>

            <button className="accordion">What are the benefits of holding a BenNFT?</button>
            <div className="panel">
              <p>BenNFTs grant you exclusive access to Conor and his legacy. These NFTs give you unmatched
                opportunities and benefits, including but not limited to: Fight Tickets, Press Conference Access,
                Meet & Greets, Live Training Sessions, Signed Memorabilia, 1-on-1 Calls, Virtual Hangouts, and
                Behind-The-Scenes access to Conor’s life.
              </p>
            </div>

            <button className="accordion">How much do they cost?</button>
            <div className="panel">
              <p>There are two pricing options for BenNFTs. Early Allow List (Presale) can purchase a
                BenNFT for £200 GBP and the Public Sale can purchase a BenNFT for £250 GBP.</p>
            </div>

            <button className="accordion"> What is the Allow List and how can I get on it?</button>
            <div className="panel">
              <p>The Allow List is to reward our early supporters of Conor and the project. The Allow List can
                purchase a BenNFT for 20% off (£200 GBP) and has a 3 hour purchase period before the
                Public Sale. <br />
                You can get on the Allow List by joining our Discord, engaging with the community, playing
                weekly games, and interacting with us on Twitter.

              </p>
            </div>

            <button className="accordion">Will each NFT be unique?</button>
            <div className="panel">
              <p>Yes, every single NFT will be unique with traits. There will also be rares and special 1/1 NFTs
                that will offer even more utility than the rest.</p>
            </div>

            <button className="accordion">How can I stay up to date?</button>
            <div className="panel">
              <p>Stay up to date with the latest news and announcements by joining our Discord and following us
                on Twitter.
              </p>
            </div>

            <button className="accordion">Where can I buy a BenNFT if I miss the sale?
            </button>
            <div className="panel">
              <p>BenNFTs will be available for purchase on a secondary market such as Opensea.</p>
            </div>

          </div>
        </div>
      </div>
      {/* // TIMELINE START */}
      <div id="timelineContainer">
        <h1 style={{ textAlign: "center", margin: "50px", color: "#cacaca", fontSize: "35px", fontFamily: "Orbitron", fontWeight: "900" }}>The BenNFT Roadmap</h1>
        <ul className="timeline">

          <li>
            <div className="direction-r">
              <div className="flag-wrapper">
                <span className="flag">LAUNCH</span>
              </div>
              <div className="desc">Launch Discord/Website <br /><br />Artwork Previews<br /><br />Open Allowlist</div>
            </div>
          </li>

          <li>
            <div className="direction-l">
              <div className="flag-wrapper">
                <span className="flag">25% Minted</span>
                <span className="time-wrapper"><span className="time">Raffle</span></span>
              </div>
              <div className="desc">Raffle for The Connor Brothers art</div>
            </div>
          </li>

          <li>
            <div className="direction-r">
              <div className="flag-wrapper">
                <span className="flag">33% Minted</span>
                <span className="time-wrapper"><span className="time">Boots</span></span>
              </div>
              <div className="desc">Raffle for Conor's Boxing Boots he used in a match</div>
            </div>
          </li>

          <li>
            <div className="direction-l">
              <div className="flag-wrapper">
                <span className="flag">50% Minted</span>
                <span className="time-wrapper"><span className="time">Content</span></span>
              </div>
              <div className="desc">AMA with Conor Benn<br /><br />BTS access to watch training session</div>
            </div>
          </li>

          <li>
            <div className="direction-r">
              <div className="flag-wrapper">
                <span className="flag">75% Minted</span>
                <span className="time-wrapper"><span className="time">Raffle</span></span>
              </div>
              <div className="desc">Snapshot for standard seat raffle</div>
            </div>
          </li>

          <li>
            <div className="direction-l">
              <div className="flag-wrapper">
                <span className="flag">100% Minted</span>
                <span className="time-wrapper"><span className="time">Raffles</span></span>
              </div>
              <div className="desc">Snapshot for Conor's robe raffle<br /><br />Snapshot for Conor's gloves raffle<br /><br />Raffle for ringside seats</div>
            </div>
          </li>

          <li>
            <div className="direction-r">
              <div className="flag-wrapper">
                <span className="flag">Winners Drawn</span>
              </div>
              <div className="desc">The winners from all snapshots will be revealed.</div>
            </div>
          </li>

          <li>
            <div className="direction-l">
              <div className="flag-wrapper">
                <span className="flag">Additional Utility</span>
              </div>
              <div className="desc">More TBD and upcoming fight.</div>
            </div>
          </li>

        </ul>
      </div>
      {/* // TIMELINE END */}
      <footer className="site-footer" style={{ display: "flex", justifyContent: "center", background: "transparent", zIndex: "10", boxShadow: "0px -5px 60px 40px #000000ad" }}>

        <div className="container" style={{ display: "flex", justifyContent: "center", width: "50%" }}>

          <div className="row" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "space-evenly", width: "100%" }}>
            <div>
              <h2 style={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "space-evenly", width: "100%", fontSize: "40px", fontFamily: "Orbitron" }}>BenNFT</h2>
            </div>


            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>

              <div className="col-xs-6 col-md-3" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", margin: "25px 0px" }}>
                <h6>Community</h6>
                <ul className="footer-links">
                  <li><a href="https://discord.gg/bennft">Talk to us on discord!</a></li>
                  <li><a href="https://www.instagram.com/conorbennofficial/">Conor's Instagram</a></li>
                </ul>
              </div>

            </div>

            <p style={{ textAlign: "center" }}>Copyright © 2022 BenNFT. All Rights Reserved </p>
          </div>

        </div>


      </footer>

    </s.Screen>
  );
}

export default App;
